<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">ACTIVITY </span> LOGS
    </PageHeader>

    <v-row justify="center">
      <v-col cols="8">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="subtitle-1"> Search Staff </v-toolbar-title>
            <v-autocomplete
              @change="getStaffAuthLogsBtn"
              :loading="loadStaff"
              append-icon="mdi-account-search"
              v-model="staff"
              :items="staffBasket"
              :search-input.sync="searchStaff"
              @keyup="debouncesearchStaff"
              cache-items
              hide-details
              :filter="customFilter"
              flat
              item-text="title_name"
              item-value="id"
              label="Staff Email"
              solo-inverted
              class="mx-4"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="loadStaff">
                    <span class="mr-4">
                      <v-progress-circular
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    Searching
                    <strong>Staff...</strong>
                  </v-list-item-title>

                  <v-list-item-title v-else>
                    No
                    <strong>Staff Found...</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- <ResizeComponent>
          <template v-slot:table="tableProps"> :height="tableProps.tableHeight - 17"-->
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          item-key="id"
          hide-default-footer
          disable-pagination
          :loading="isLoaded"
        >
          <template #item.updated_at="{ value }">
            {{ moment(value).format("MMMM Do YYYY, h:mm:ss a") }}
          </template>
        </v-data-table>
        <v-card v-if="getters_staff_auth_logs.length" v-intersect="intersected">
          <v-progress-linear
            :indeterminate="true"
            v-if="loadingScroll"
          ></v-progress-linear>
        </v-card>
        <!-- </template>
        </ResizeComponent> -->
      </v-col>
    </v-row>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
  import { defineComponent, getCurrentInstance, provide, reactive, toRefs } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import ResizeComponent from "@/components/slots/ResizeComponent.vue";

  export default defineComponent({
    components: { PageHeader, ResizeComponent },
    setup() {
      const vm = getCurrentInstance()
      const { getStaffAuthLogs, searchStaffAuth, signOut } = useActions([
        "getStaffAuthLogs",
        "searchStaffAuth",
        "signOut",
      ]);

      const {
        getters_log,
        getters_auth_staff,
        getters_staff_auth_logs,
        getters_staff_auth_logs_meta,
      } = useGetters([
        "getters_log",
        "getters_auth_staff",
        "getters_staff_auth_logs",
        "getters_staff_auth_logs_meta",
      ]);

      const searchStaffHeader = reactive({
        loadStaff: false,
        staff: null,
        staffBasket: [],
        searchStaff: null,
        overlay: false,
      });

      const { staff, staffBasket, loadStaff, overlay, searchStaff } =
        toRefs(searchStaffHeader);

      const logTable = reactive({
        headers: [
          { text: "DATE", value: "updated_at" },
          // { text: "DONE BY", value: "user.title_name" },
          { text: "USER AGENT", value: "user_agent" },
          { text: "IP ADDRESS", value: "ip_address" },
        ],
        color: "",
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        page: 1,
        loadingScroll: false,
        isIntersecting: false,
        items: [],
        isLoaded: false,
      });

      const {
        color,
        msgHeader,
        msgBody,
        msgIcon,
        page,
        loadingScroll,
        isIntersecting,
        items,
        isLoaded,
      } = toRefs(logTable);

      const debouncesearchStaff = debounce(() => {
        if (searchStaff.value) {
          if (staffBasket.value.length > 0) return;
          loadStaff.value = true;

          searchStaffAuth(staff.value).finally(() => {
            loadStaff.value = false;
            staffBasket.value = getters_auth_staff.value;
          });
        }
      });

      const customFilter = (item, queryText /* itemText */) => {
        const textOne = item.title.toLowerCase();
        const textTwo = item.othernames.toLowerCase();
        const textThree = item.lastname.toLowerCase();
        const textFour = item.corporate_email.toLowerCase();
        const searchText = queryText.toLowerCase();

        return (
          textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1 ||
          textThree.indexOf(searchText) > -1 ||
          textFour.indexOf(searchText) > -1
        );
      };

      const getStaffAuthLogsBtn = () => {
        overlay.value = true;
        isLoaded.value = true;
        getStaffAuthLogs({ page: page.value, staff: staff.value })
          .then(() => {
            isLoaded.value = false;
            items.value = getters_staff_auth_logs.value;
          })
          .catch((e) => {
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;

              case 401:
                msgBody.value = e.response.data.message;
                break;
              case 403:
                msgBody.value = e.response.data.messae;
                break;

              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            overlay.value = false;
          });
      };

      const intersected = (entries) => {
        isIntersecting.value = entries[0].isIntersecting;
        if (!isIntersecting.value) {
          loadingScroll.value = true;
          return;
        }
        if (
          getters_staff_auth_logs_meta.value.current_page >=
          getters_staff_auth_logs_meta.value.last_page
        ) {
          loadingScroll.value = false;
          return;
        }

        page.value++;
        console.log(loadingScroll.value);

        getStaffAuthLogs({ page: page.value, staff: staff.value }).then(() => {
          loadingScroll.value = false;
          items.value = [
            ...new Set([...items.value, ...getters_staff_auth_logs.value]),
          ];
        });
      };

      provide("color", color);

      return {
        getters_log,
        ...toRefs(logTable),
        ...toRefs(searchStaffHeader),
        debouncesearchStaff,
        getStaffAuthLogsBtn,
        customFilter,
        intersected,
        getters_staff_auth_logs,
      };
    },
  });
</script>

