<template>
  <div v-resize="onResize">
    <div ref="resizableDiv">
      <slot name="table" :table-height="tableHeight" />
    </div>
  </div>
</template>

<script>
  import { defineComponent, getCurrentInstance, reactive, toRefs } from "vue";

  export default defineComponent({
    setup() {
      const vm = getCurrentInstance()
      const resizeComp = reactive({
        tableHeight: 0,
      });

      const { tableHeight } = toRefs(resizeComp);

      const onResize = () => {
        tableHeight.value =
          window.innerHeight -
          vm.proxy.$refs.resizableDiv.getBoundingClientRect().y;
      };

      return {
        ...toRefs(resizeComp),
        onResize,
      };
    },
  });
</script>


<script>
  export default {
    name: "resizable-page",
    data() {
      return {
        tableHeight: 0,
      };
    },
    props: {
      footerHeight: {
        type: Number,
        default: 59, //default v-data-table footer height
      },
    },
    methods: {
      onResize() {
        this.tableHeight =
          window.innerHeight -
          this.$refs.resizableDiv.getBoundingClientRect().y -
          this.footerHeight;
      },
    },
  };
</script>